import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'floorplan',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      upgrade: z.union([z.literal('plus').optional(), z.literal('3d').optional()]),
      patio: coerceBoolean().optional(),
      garage: coerceBoolean().optional(),
    }),
  },
  {
    name: '2D Floor Plan',
    thumbnail: 'tenant/twt/floorplan/standard2.png',
    short:
      "Capture a clear, bird's-eye view of your property, allowing buyers to grasp the relationships between rooms, their sizes, and how they connect.",
    tenant: TenantId.TWIST_TOURS,
    images: [],
  },
);
