import React from 'react';
import {
  TwtPackage3dInclude,
  TwtPackageFloorplanInclude,
  TwtPackagePhotoAddOns,
  TwtPackagePhotoInclude,
} from '~/tenants/twt/package/common';
import { TenantPackageIncludes } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import TwtMatterportConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';
import { PackageFormNested } from '~/components/performable/PackageForm';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TwtOrderType } from '~/tenants/twt/model/TwtOrder';
import { TwtMatterportFormInputs } from '~/tenants/twt/performable/matterport/TwtMatterportForm';
import Heading from '~/components/layout/Heading';

export default function TwtMatterportTourForm() {
  return (
    <>
      <TenantPackageIncludes>
        <TwtPackagePhotoInclude />
        <TwtPackageFloorplanInclude />
        <TwtPackage3dInclude />
      </TenantPackageIncludes>
      <div className="border-theme-separator border-t pt-6 mt-6">
        <Heading title="Matterport Information" />
        <PackageFormNested performable={TwtMatterportConfig}>
          <TwtMatterportFormInputs persona={PersonaType.CUSTOMER} />
        </PackageFormNested>
      </div>
      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <TwtPackagePhotoAddOns type={TwtOrderType.FOR_SALE_LISTING} />
      </TenantPerformableAddOns>
    </>
  );
}
