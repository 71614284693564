import { FormHorizontal } from '~/components/form/layout';
import FormGroup from '~/components/form/FormGroup';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TwtOrderContext, TwtOrderType, TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import TwtPhotosAddOns from '~/tenants/twt/performable/photo/TwtPhotosAddOns';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';

export default function TwtPhotoForm({ context, create, persona }: TenantPerformableFormProps<TwtOrderContext>) {
  const type = useZodFormFieldSingleValue<TwtPhotoType>('type');

  const addons = type ? (
    <TenantPerformableAddOns persona={persona}>
      <TwtPhotosAddOns persona={persona} type={type} order={context.metadata.type} />
    </TenantPerformableAddOns>
  ) : (
    <></>
  );

  if (context.metadata.subtype === TwtPropertyType.LAND) {
    return (
      <>
        <ZodFieldHidden name="type" value={TwtPhotoType.EXTERIOR_ONLY} />
        {addons}
      </>
    );
  }

  let types = (
    <FormHorizontal name="type" label="Type">
      <ZodFieldSelect
        options={[
          { label: 'Interior + Exterior', value: TwtPhotoType.INTERIOR_EXTERIOR },
          { label: 'Exterior Only', value: TwtPhotoType.EXTERIOR_ONLY },
          { label: 'Reshoot (Interior + Exterior)', value: TwtPhotoType.RESHOOT },
        ]}
        name="type"
      />
    </FormHorizontal>
  );

  if (context.metadata.type === TwtOrderType.COMMERCIAL) {
    types = <ZodFieldHidden name="type" value={TwtPhotoType.INTERIOR_EXTERIOR} />;
  }

  let photos = <></>;

  if ([TwtOrderType.SHORT_TERM_RENTAL, TwtOrderType.LONG_TERM_RENTAL].includes(context.metadata.type)) {
    photos = (
      <FormHorizontal name="photos" label="Photos">
        <ZodFieldSelect
          options={[
            { label: '20 Photos', value: '20' },
            { label: '30 Photos', value: '30' },
            { label: '40 Photos', value: '40' },
          ]}
          name="photos"
        />
      </FormHorizontal>
    );

    types = <ZodFieldHidden name="type" value={TwtPhotoType.INTERIOR_EXTERIOR} />;
  } else if (type && [TwtPhotoType.EXTERIOR_ONLY, TwtPhotoType.RESHOOT].includes(type)) {
    photos = (
      <FormHorizontal name="photos" label="Photos">
        <ZodFieldSelect
          options={[
            { label: '5 Photos', value: '5' },
            { label: '10 Photos', value: '10' },
            { label: '15 Photos', value: '15' },
          ]}
          name="photos"
        />
      </FormHorizontal>
    );
  }

  return (
    <FormGroup>
      {create ? types : <ZodFieldHidden name="type" />}
      <FormHorizontal label="Special Instructions">
        <ZodFieldTextarea name="instructions" />
      </FormHorizontal>
      {photos}
      {addons}
    </FormGroup>
  );
}
