import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import { PersonaType } from '~/tenants/common/TenantPersona';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import FormGroup from '~/components/form/FormGroup';

export function TwtMatterportFormInputs({ persona }: { persona: PersonaType }) {
  return (
    <FormGroup>
      <FormHorizontal label="Floors">
        <ZodFieldInput type="number" name="floors" />
      </FormHorizontal>
      <FormHorizontal label="Include Patio">
        <ZodFieldSelect
          name="patio"
          options={[
            { label: 'No', value: 'false' },
            { label: 'Yes', value: 'true' },
          ]}
        />
      </FormHorizontal>
      <FormHorizontal label="Include Detached Garage">
        <ZodFieldSelect
          name="garage"
          options={[
            { label: 'No', value: 'false' },
            { label: 'Yes', value: 'true' },
          ]}
        />
      </FormHorizontal>
      {persona === PersonaType.OFFICE && (
        <div>
          https://support.matterport.com/s/article/How-To-Publicly-Share-a-Space?language=en_US#Getting-the-Showcase-Link
          <FormHorizontal label="Branded URL">
            <ZodFieldInput name="branded_url" />
          </FormHorizontal>
          <FormHorizontal label="Unbranded URL">
            <ZodFieldInput name="unbranded_url" />
          </FormHorizontal>
        </div>
      )}
    </FormGroup>
  );
}

export default function TwtMatterportForm({ create, persona }: TenantPerformableFormProps<TwtOrderContext>) {
  return (
    <div className="space-y-4">
      {persona === PersonaType.CUSTOMER && create && (
        <iframe
          src="https://my.matterport.com/show/?m=mMqN2oQ5dPa"
          className="w-full h-[400px] rounded-theme overflow-hidden"
        />
      )}
      <TwtMatterportFormInputs persona={persona} />
    </div>
  );
}
