import { TenantPerformableAddOns } from '~/tenants/common/performable';
import TwtFloorplanUpgrade from '~/tenants/twt/performable/floorplan/TwtFloorplanUpgrade';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import FormGroup from '~/components/form/FormGroup';

export default function TwtFloorplanForm({ persona }: TenantPerformableFormProps<TwtOrderContext>) {
  return (
    <>
      <FormGroup>
        <FormHorizontal label="Include Patio">
          <ZodFieldSelect
            name="patio"
            options={[
              { label: 'No', value: 'false' },
              { label: 'Yes', value: 'true' },
            ]}
          />
        </FormHorizontal>
        <FormHorizontal label="Include Detached Garage">
          <ZodFieldSelect
            name="garage"
            options={[
              { label: 'No', value: 'false' },
              { label: 'Yes', value: 'true' },
            ]}
          />
        </FormHorizontal>
      </FormGroup>
      <TenantPerformableAddOns persona={persona}>
        <TwtFloorplanUpgrade persona={persona} />
      </TenantPerformableAddOns>
    </>
  );
}
